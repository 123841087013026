.hilfe-layout {
  left: 15px;
  position: relative;
  font-size: $font-size-nav-top;

  a {
    color: $text-color;

    &:hover {
      color: $red;
      text-decoration: underline;
    }
  }
}

.hilfe-leaf-layout {
  list-style-type: none;
  position: relative;
  left: -25px;
}

.hilfe-icon {
  position: relative;
  left: -17px;
  top: -2px;
}

.hilfe-branch-span {
  position: relative;
  left: -16px;
}

.hilfe-modal-body {
  margin-top: -30px;
  padding: 25px;
  white-space: pre-line;
  $dt-width: 120px;
  .contact {
    dt {
      font-weight: normal;
      float: left;
      width: $dt-width;
    }
  }

  .modal-row {
    display: flex;
    align-items: center;
    padding: 5px;

    .modal-label {
      width: 70px;
    }
  }
}
