@import '../settings/fonts';
@import '../settings/colors';

h1 {
  font-size: $font-size-h1;
}

h2 {
  font-size: $font-size-h2;
}

.heading {
  font-size: 60px;
  font-size: 3.75rem;
  font-weight: 500;
}

.red-heading {
  color: $manz-red;
  font-weight: 500;
  letter-spacing: 0.03rem;
  svg {
    .cls-1 {
      fill: none;
      stroke: $manz-red;
    }
  }
}
