$input-width: 25px;
$input-height: 25px;

/* Customize the label (the.checkbox-container) */
.checkbox-container {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: $input-width + 5px;
  height: $input-height;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .begehren-content & {
    margin-top: $spacing-xs;
  }
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radio, .checkmark {
  width: 25px;
  height: 25px;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  background-color: transparent;
  background-size: 200%;
  background-position: center;
  //z-index: 2;
}

/* initial icon */
.radio {
  background-image: url(../../../assets/icons/mz-radio-button.svg);
}
.checkmark {
  background-image: url(../../../assets/icons/mz-checkbox.svg);
}

/* disabled icon */
.checkbox-container-disabled:hover input,
.checkbox-container input:disabled{
    ~ .radio{
      pointer-events: none;
      background-image: url(../../../assets/icons/mz-radio-disable.svg);
    }
    ~ .checkmark {
      pointer-events: none;
      background-image: url(../../../assets/icons/mz-checkbox-disable.svg);
    }
  }


/* checked icon */
.checkbox-container input:checked {
  ~ .radio{
    background-image: url(../../../assets/icons/mz-radio-button-active.svg);
  }
  ~ .checkmark {
    background-image: url(../../../assets/icons/red/mz-checkbox-check.svg);
  }
}

/* checked and disabled icon*/
.checkbox-container input:checked:disabled {
  ~ .radio{
    pointer-events: none;
    background-image: url(../../../assets/icons/mz-radio-check.svg);
  }
  ~ .checkmark {
    pointer-events: none;
    background-image: url(../../../assets/icons/mz-checkbox-check-disable.svg);
  }
}

.checkbox-flex {
  .form-check-label {
    white-space: normal;
  }
  .checkbox-container {
    height:auto;
  }
}

.check-table-container {
  padding-left: 40px;
  padding-right: 0;
  position: relative;
  width: 115px;

  .checkbox-container {
    position: absolute;
    left: 10px;
    top: 10px;
    margin-top:0;
  }
}

.check-table{
  .selectInput {
    position: relative;
    left: 102px;
  }
}
