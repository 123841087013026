.schriftsatz-header{
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: $spacing-md;
	@include phone {
		flex-direction: column;
		align-items: flex-end;
	}
}

.schriftsatz-header-wrapper {
	justify-content: space-between;
}

.schriftsatzBasisdatenLayout {
	min-height: 260px;
}

.error-info-text {
	margin-left: 10px;
}

.error-info-erneut-senden-btn {
	margin-left: 15px;
	margin-top: 2px;
	width: 183px;
}

.uebertragungsfehler-model {
	text-align: center;
}
