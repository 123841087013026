@import '../settings/colors';

.progressBar {
  height: 8px;
  background-color: $manz-gray-medium;

  .progress {
    height: inherit;
    background-color: $manz-red;
    transition: width 1s linear;
    border-radius: 0;
  }
}

.progress[value] {
  @include border-radius(25px);
  -webkit-appearance: none;
  appearance: none;
  width: 250px;
  height: 20px;

  &::-webkit-progress-bar {
    background-color: $manz-gray-light;
  }

  &::-webkit-progress-value {
    background-color: $manz-blue;
  }
}
