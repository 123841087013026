@import '../settings/fonts';

body {
  margin: 0;
  padding: 0;
  color: $text-color;
  font-family: $font-family;
  font-size: $font-size;
}

a {
  color: $manz-red;
  &:hover{
    color: $manz-red;
  }
}
