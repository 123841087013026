@import '../settings/colors';

.badge {
  font-size: 80%;
  font-weight: normal;
  border-radius: 1.5rem;
  // width: 120px;
  // padding: 0.2em 0.4em 0.25em 0.4em;
  height: 19px;
  width: 150px;
  padding: 0.1em 0.4em 0.1em 0.4em;
  position:relative;

  .info-icon {
    position: absolute;
    left: 85%;
  }
}

.badge-pill {
  width: auto;
  padding-top: 0.4em;
}

// schriftsatz status
.badge-schriftsatz-fehler {
  background-color: $manz-schriftsatz-fehler;
  color: $manz-white;
}

.badge-schriftsatz-tst-feher {
  background-color: $manz-schriftsatz-fehler;
  color: $manz-white;
}

.badge-schriftsatz-eingebracht {
  background-color: $manz-schriftsatz-eingebracht;
  color: $manz-white;
}

.badge-schriftsatz-in-arbeit {
  background-color: $manz-schriftsatz-in-arbeit;
  color: $manz-white;
}

.badge-schriftsatz-visualisiert {
  background-color: $manz-schriftsatz-visualisiert;
  color: $manz-white;
}

.badge-schriftsatz-geprueft {
  background-color: $manz-schriftsatz-geprueft;
}

.badge-schriftsatz-uebertragunsfehler {
  background-color: $manz-schriftsatz-uebertragunsfehler;
  color: $manz-white;
}

.badge-schriftsatz-ungelesen {
  background-color: $manz-schriftsatz-ungelesen;
  color: $manz-white;
  min-width: 19px;
}

.badge-schriftsatz-gelesen {
  background-color: $manz-schriftsatz-gelesen;
  color: $manz-black;
}

.badge-schriftsatz-gesendet {
  background-color: $manz-schriftsatz-gesendet;
  color: $manz-black;
  min-width: 19px;

  &-blink {
    @extend .badge-schriftsatz-gesendet;
    animation: changeshadow 2s ease-in infinite;
    -webkit-animation: changeshadow 2s linear infinite;
    -moz-animation: changeshadow 2s linear infinite;
    -ms-animation: changeshadow 2s linear infinite;
    -o-animation: changeshadow 2s linear infinite;
  }
}

.badge-schriftsatz-default {
  background-color: $manz-green;
  color: $manz-white;
  min-width: 19px;

  &-blink {
    @extend .badge-schriftsatz-gesendet;
    animation: changeshadow 2s ease-in infinite;
    -webkit-animation: changeshadow 2s linear infinite;
    -moz-animation: changeshadow 2s linear infinite;
    -ms-animation: changeshadow 2s linear infinite;
    -o-animation: changeshadow 2s linear infinite;
  }
}

.uebertragungsfehler-send-btn {
  background-color: $uebertragunsfehler-send-btn !important;
  color: $manz-black;

  &-blink {
    @extend .uebertragungsfehler-send-btn;
    animation: changeshadow 2s ease-in infinite;
    -webkit-animation: changeshadow 2s linear infinite;
    -moz-animation: changeshadow 2s linear infinite;
    -ms-animation: changeshadow 2s linear infinite;
    -o-animation: changeshadow 2s linear infinite;
  }
}

.uebertragungsfehler-send-btn-text {
  color: $manz-schriftsatz-uebertragunsfehler !important;
  font-weight: bold !important;

  &-blink {
    @extend .uebertragungsfehler-send-btn-text;
    animation: changeshadow 2s ease-in infinite;
    -webkit-animation: changeshadow 2s linear infinite;
    -moz-animation: changeshadow 2s linear infinite;
    -ms-animation: changeshadow 2s linear infinite;
    -o-animation: changeshadow 2s linear infinite;
  }
}

.error-info-send-btn {
  font-weight: bold !important;

  &-blink {
    @extend .error-info-send-btn;
    animation: changeshadow 3s ease-in infinite;
    -webkit-animation: changeshadow 3s linear infinite;
    -moz-animation: changeshadow 3s linear infinite;
    -ms-animation: changeshadow 3s linear infinite;
    -o-animation: changeshadow 3s linear infinite;
  }
}

@keyframes changeshadow {
  0% {
    opacity: .4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: .4;
  }
}

@-webkit-keyframes changeshadow {
  0% {
    opacity: .4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: .4;
  }
}

@-moz-keyframes changeshadow {
  0% {
    opacity: .4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: .4;
  }
}

@-ms-keyframes changeshadow {
  0% {
    opacity: .4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: .4;
  }
}

@-o-keyframes changeshadow {
  0% {
    opacity: .4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: .4;
  }
}

.uebertragungsfehler-1 {
  width: 121px;
  height: 90px;
  overflow: hidden;
  margin: auto;
  position: relative;
  left: -5px;
  top: 2px;
  font-size: 12px;

  @keyframes anis {
    100% {
      transform:translateX(-275px);
      -webkit-transform: translateX(-275px);
    }
  }span {
     position:absolute;
   }
  span {
    animation:anis 7s linear infinite;
  }
}
.uebertragungsfehler-2 {
  animation:anis 10s linear infinite;
}
.uebertragungsfehler-2:hover {
  animation-play-state:paused;
}

@keyframes manz-spinner-grow {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

.manz-spinner-grow {
  -webkit-animation: manz-spinner-grow .75s linear infinite;
  animation: manz-spinner-grow .75s linear infinite;
  animation-iteration-count: 2;
}
