.breadcrumbsLayout {
  background-color: $manz-bg;
  padding: $spacing-lg 0;
  padding-left: 1rem;

  @include phone{
    padding: $spacing-xs 0;
  }

  .breadcrumbs {
    display: flex;
    max-width: $content-width;
    flex-direction: row;
    margin: auto;

    .flex {
      align-items: center;
    }
    .breadcrumbText {
      font-size: $font-size;
    }

    .homeStyle {
      position: relative;
      margin-right: 8px;
    }

    .arrowIcon {
      width: 25px;
      height: 25px;
    }
  }
}
