@import '../settings/colors';
@import '../settings/fonts';

$link-border-width: 3px;

.navigation-group {
  width: 100%;
  font-size: 15px;

  &:not(:last-of-type){
    margin-bottom: 10px;
  }

  .navigation-group-header {
    color: $text-color;
    font-weight: bold;
    font-size: $font-size-sm;
    padding: 2px 0;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    white-space: nowrap;
    display: flex;
    align-items: center;
    height: 30px;
    a {
      border-bottom: $link-border-width solid transparent;
      color: $text-color;
      width: 100%;
      height: 28px;
    }

    img, svg {
      width: 30px;
      height: 30px;
      margin-right: $spacing-xs;
    }

    &:hover {
      a {
        color: $manz-red;
        @include coloredSVG($manz-red);
        text-decoration: none;
      }
    }
    .isActive {
      color: $manz-red;
      text-decoration: none;
      @include coloredSVG($manz-red);
      border-bottom: $link-border-width solid $manz-red;
    }
  }

  .navigation-group-items,
  .navigation-group-items-burger-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    white-space: nowrap;

    li {
      padding: 2px 0 2px 35px;
      width: inherit;

      .content & {
        padding-left: 2px;
      }

      a, button {
        width: 100%;
        display: inline-block;
        padding: 1px $spacing-xxs;
        color: $text-color;
        border-bottom: $link-border-width solid transparent;
        outline: none;

        &:not(:disabled) {
          &:hover,
          &.active {
            color: $manz-red;
            text-decoration: none;
          }
        }
        &.active {
          border-bottom-color: $manz-red;
        }
      }
    }
  }
}


// paging at simple table
.table-paging {
  $select-width: 130px;

  button.active {
    background-color: $manz-gray-light;
  }

  .table-paging-placeholder {
    width: $select-width;
  }

  .select-paging {
    display: flex;
    align-items: center;
    width: $select-width;
    justify-content: space-between;

    .select-text {
      color: $manz-gray-medium;
    }

    select {
      border: none;
      border-bottom: 2px solid $text-color;
      color: $text-color;
      padding: $spacing-xs $spacing-sm;

      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      padding-right: 30px;
      background: url('../../../assets/icons/mz-arrow-down.svg') no-repeat 90% 50%;
      background-size: 20px 20px;
    }
  }

  .btn-group {
    display: flex;
    align-items: center;
    .pages-text {
      margin: 0 $spacing-sm;
    }
  }
}


// navigations in content-window
.content {
  .navigation-group {
    width: 300px;
    min-width: 300px;
    @media(max-width:$break-xs){
      width:100%;
    }

    button {
      border: none;
      background-color: transparent;
      text-align: left;
      &:not(:disabled){
        &:hover, &.active {
          color: $manz-red;
        }
      }
      &:disabled {
        opacity: .5!important;
        color: $manz-gray;
      }
    }
  }
}


.overview-new {

  $container-width: 230px;

  .group {
    display: flex;
    flex-wrap: wrap;
    .entry {
      @extend .btn-secondary;
      color: $text-color;
      border: none;
      width: $container-width;
      height: 50px;
      margin: $spacing-sm;
      padding: $spacing-xs $spacing-sm;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      &:hover:not(:disabled), &.active:not(:disabled) {
        text-decoration: none;
        color: $manz-red!important;
        font-weight: 500;
        .dropdown-icon{
          .cls-1 {
            stroke: $manz-red;
          }
        }
      }
      &:disabled {
        background-color: $manz-gray-light;
        color: $manz-gray-medium;
        .dropdown-icon {
          .cls-1 {
            stroke: $manz-gray-medium;
          }
        }
      }
      &:focus {
        box-shadow: none!important;
        text-decoration: none;
      }
      & + .active {
        outline: none!important;
      }

      .dropdown-icon, .label {
        margin-left: auto;
        /* IE11 CSS styles */
        _:-ms-fullscreen, :root & {
          margin-left: 0;
          margin-right: auto;
        }
      }
      .dropdown-icon {
        min-width: 25px;
      }
    }

    .submenu {
      background-color: $white;
      border-top: 3px solid $manz-red;
      margin-top:-3px;
      width: 375px;
      max-width: 100%;
      @include box-shadow(0 5px 14px 0 rgba(0, 0, 0, 0.15));
      display:flex;
      flex-direction: column;

      .menu-title {
        font-weight: bold;
        margin-bottom: -7px;
      }

      .submenu-entry {
        padding: 7px $spacing-sm;
        &.active:hover{
          background-color: $manz-gray-light-hover;
        }

      }
      .menu-separator {
        border-bottom: $manz-gray 1px solid;
        margin: 10px;
        padding: 0;
      }
      @include phone{
        .submenu-entry {
          &:nth-child(even) {background: $manz-gray-light};
        }
      }
    }
  }
}

.popper {
  z-index: 2;
}

//Erledigung mobiles Menu
.mobil-menu {
  .submenu {
    @include box-shadow(0 0 14px 0 rgba(0, 0, 0, 0.15));
    color: $text-color;
    font-size: $font-size-nav-top;
    padding: 10px;
    background: white;
    width:280px;
  }
}


// navigation in schriftsatz

.nav-tabs-container {
  &.grey-background {
    background-color: $manz-gray-light;
    height: 44px;
    @media (max-width: $break-lg) {
      height: auto;
    }
  }

  .btnActive {
    border-bottom: 3px solid $manz-red !important;
    color: $manz-red !important;
  }

  .btnActiveParent {
    border-bottom: 3px solid $manz-red !important;
    color: $manz-red !important;
    top: -7px !important;
  }

  .lineWithArrow {
    position: relative;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid $manz-red;
    color: transparent;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
  }

  .lineWithoutArrow {
    position: relative;
    width: 0;
    height: 0;
    border-left: 0 solid transparent;
    border-right: 0 solid transparent;
    border-top: 7px solid #ad2612;
    color: transparent;
    top: 42px;
    left: 50%;
    transform: translateX(-50%);
  }

  .divider {
    top: -16px;
  }

  .blueThema {
    background-color: $manz-collapse-background-in;
  }
}

