// Importing Variables from Main Project
@import '../../Main/styles/settings/colors';
@import '../../Main/styles/settings/variables';
@import '../../Main/styles/settings/fonts';
@import '../../Main/styles/tools/mixins';


.redCircle {
  @include border-radius(50%);
  transform: rotate(6deg);
  background: $red;
  color: white;
  display: flex;
  @include font-size-rem($xxl-font-size);
  align-items: center;
  text-align: center;
  width: 200px;
  height: 200px;
  margin: auto;

  .largeText {
    @include font-size-rem($xxxxl-font-size);
    font-weight: 500;
    line-height: 0.9;
  }
  div {
    margin: auto;
    position: relative;
    top: 0;
  }
}

@media (min-width: $break-md) {
  .redCircle {
    position: absolute;
    right: 0;
    bottom: -75px;
  }
}
@media (max-width: $break-md) {
  .redCircle {
    zoom: 0.5;
    position: absolute;
    right: 0;
    bottom: -75px;
  }
}
