
.gb-auszug-begehren-grundstueck-btn {
  display: flex;
}

.gb-auszug-begehren-add-btn {
  display: flex;

  > span {
    position: relative;
    top: -11px;
    left: -7px;

    > div > div:first-child {
      position: relative;
      bottom: -6px;
      width: 166px;
    }
  }
}

.gb-auszug-beteiligte-add-btn {
  display: flex;

  > span {
    position: relative;
    top: -11px;
    left: -7px;

    > div > div:first-child {
      position: relative;
      bottom: -6px;
      min-width: 192px;
    }
  }
}

.gb-auszg-begehren-add-btn-ezkg {
  font-size: 12px !important;
}

.gb-auszg-beteiligte-add-btn-ezkg {
  font-size: 13px !important;
  max-height: 11px;
}

.gb-auszug-begehren-add-btn-pr-anmerkung {
  display: flex;
  width: 238px;
  height: 8px;
  position: relative;
  top: -2px;

  > span {
    position: relative;
    top: -8px;
    left: -7px;

    > div > div:first-child {
      position: relative;
      bottom: -6px;
      width: 192px;
    }
  }
}

.gb-auszug-pdf-anzeigen {
  position: relative;
  left: 76px;
  top: -8px;

  > div > button {
    font-size: 13px;
  }
}

.gb-auszug-xml-anzeigen {
  position: relative;
  left: 82px;

  > div > button {
    font-size: 13px;
  }
}

.gb-auszug-delete {
  position: relative;
  left: 88px;

  > button {
    font-size: 13px;
  }
}

.gb-auszug-begehren-select-auszug {
  position: relative;
  left: 10px;
}

.gb-auszug-begehren-header-select {
  position: relative;
  top: 10px;
}

.gb-auszug-beteiligter-header-label {
  position: relative;
  bottom: 15px;
}

.gb-auszug-options-select {
  > div {
    width: 362px;
  }
}

.text-array-item-extra-info {
  width: 40px;
  height: 31px;
  position: relative;
  left: 32px;
  top: 1px;
}

.blnr-list-info-icon {
  width: 31px;
  height: 23px;
  position: relative;
  top: -2px;
  left: -8px;
}

.blnr-list-info-icon-eigentumitem {
  width: 31px;
  height: 23px;
  position: relative;
  top: 1px;
  left: 24px;
}

.blnr-list-info-text {
  position: relative;
  left: -6px;
  color: $manz-red;
}

.gb-antrag-abfrage {
  background-color: $manz-gray-light;
  margin-bottom: 10px;

  .titel {
    text-align: center;
    padding-top: 10px;
  }
}

.gb-antrag-abfrage-btn {
  padding-bottom: 10px;
}

.gb-auszug-for-person {
  font-size: 11px;
  color: $manz-blue;
}

.gb-auszug-uebernehmen-als {
  font-size: 13px;
}

.gb-auszug-begehren-so-aenderung {
  height: 26px;
}

.gb-auszug-eigentumsanteile-extra-info {
  display: flex;
}

.gb-auszug-eigentumsanteile-extra-info-icon {
  position: relative;
  top: 6px;
}

.gb-auszug-ezkgclnr-extra-info-icon {
  width: 31px;
  height: 23px;
  position: relative;
  left: -27px;
  margin-right: -30px;
}


.gb-auszug-eigentumsanteile-extra-info-delete {
  position: relative;
  left: 0px;
}

.gb-auszug-option-buttons {
  width: 19px !important;
  height: 19px !important;
  min-width: 19px !important;
  min-height: 19px !important;

  > svg {
    width: 19px !important;
    height: 19px !important;
    min-width: 19px !important;
    min-height: 19px !important;
  }
}

.gb-auszug-header {
  margin-top: 23px;
}

.gb-auszug-header-buttons {
  top: 3px;
  position: relative;
  min-height: 31px;
}

.gb-auszug-context-menu {
  width: 182px;
  text-align: center;

  > a > span {
    color: $manz-blue;
    text-decoration: underline;
  }

  :hover {
    color: $manz-blue-dark;
  }
}
