@import '../settings/spacings';

.ReactVirtualized__Table__headerColumn:first-of-type {
  margin: 0;
}

.ReactVirtualized__Table__headerColumn:first-of-type, .ReactVirtualized__Table__rowColumn:first-of-type {
  margin: 0;
}

.virtualized-table-header {
  padding: $spacing-sm;
  margin-right: 1px;
  cursor: pointer;
  background-color: lightgray;
  border-bottom: 1px solid gray;

  &:first-child {
    margin-right: 1px;
  }

  &:last-child {
    flex: 1;
  }
}

.virtualized-table-column {
  margin-right: 0;
  padding: $spacing-sm;
}
