.manz-divider {
  height: 0;
  margin: 0;
  overflow: hidden;
  border-top: 3px solid $manz-gray-light;
  position: relative;
}

.manz-divider-short {
  margin-left: 15px;
  margin-right: 20px;
}

.manz-divider-blank {
  margin-top: 35px;
  margin-bottom: 35px;
}

.schriftsatz-divider {
  height: 0;
  margin: 0;
  overflow: hidden;
  border-top: 3px solid $manz-gray-light;
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
}

.fb-aednerungsantrag-divder {
  border-top: 3px solid $manz-gray-medium;
  margin-top: 15px;
  margin-bottom: 15px;
}
