.toggle-switch {
}

$track-height: 20px;
$track-width: 40px;
$thumb-size: 20px;
$toggle-active: #37b258;
$toggle-active-hover: #3fcc62;
$toggle-inactive: $manz-gray;
$toggle-inactive-hover: $manz-gray-hover;

.react-toggle-track {
  height: $track-height;
  width: $track-width;
  margin-right: $spacing-sm;

  background-color: $toggle-inactive;
  .react-toggle:hover:not(.react-toggle--disabled) & {
    background-color: $toggle-inactive-hover;
  }
  .react-toggle--checked & {
    background-color: $toggle-active;
  }
  .react-toggle--checked:hover:not(.react-toggle--disabled) & {
    background-color: $toggle-active-hover;
  }
}

.react-toggle-thumb {
  height: $thumb-size;
  width: $thumb-size;
  top: $track-height/2 - $thumb-size/2;
  left: 0;
  border-color: $toggle-inactive;

  .react-toggle--checked & {
    left: $track-width - $thumb-size;
    border-color: $toggle-active;
  }
}

.react-toggle--focus {
  @include box-shadow(none !important);
  .react-toggle-thumb {
    @include box-shadow(0 0 4px rgba(0,0,0, 0.3));
  }
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  @include box-shadow(0 0 4px rgba(0,0,0, 0.3));
}
