@import '../settings/colors';

@include media-breakpoint-up(md) {
  .col-form-label {
    text-align: right;
  }
}

legend:not(.col-form-label) {
  font-size: $font-size;
  font-weight: 500;
}

.form-drop-zone, .form-drop-zone.dropzone {
  position: relative;
  border: 1px dashed $manz-gray-medium;
  border-radius: 1px;
  margin: 0;
  padding: 0;
  justify-content: flex-start;

  button.btn {
    height: 31px;
    min-height: unset !important;
  }
}


.form-validation-error {
  color: $manz-red;

  .form-drop-zone {
    @include error;
  }

  :not(.styled-table-entry) {
    .input-text, .form-control,
    textarea,
    .react-select__control {
      @include error;
    }
  }

  /* IE11 CSS styles */
  _:-ms-fullscreen, :root & {
    display: flex;
    flex-direction: column;

    .error {
      height: 20px;
    }
  }
}

.error {
  margin-top: $spacing-xs;
  margin-bottom: $spacing-xs;
  white-space: nowrap;

  svg {
    width: 15px;
    margin-top: -3px;

    .cls-1 {
      stroke: $manz-red;
    }
  }

  /* IE11 CSS styles */
  _:-ms-fullscreen, :root & {
    height: 20px;
  }
}

.error-break {
  white-space: pre-wrap;
}

.label-required {
  &:after {
    content: "*";
    margin-left: 3px;
    color: $call-to-action-blue;
  }

  & + div {
    input,
    textarea,
    .react-select__control {
      @include isRequired;
    }
  }
}

input:required {
  @include isRequired;
}

.form-check-label {
  white-space: nowrap;

  & + .form-check-label {
    margin-left: 1em;

    .form-check-input {
      margin-left: 1em;
    }
  }
}

.form-check-inline {
  height: 100%;
  /* IE11 CSS styles - workaround for fixed menu */
  _:-ms-fullscreen, :root & {
    flex-wrap: wrap;
  }
}

.form-check-inline .form-check-input {
  display: none;
}

.form-check-inline label + .form-check-input {
  margin-left: 1em;
}

.form-control-sm {
  font-size: $font-size;
}

.form-flex-center {
  display: flex;
  justify-content: center;
}

.multi-text-array {
  padding-right: 0 !important;
  padding-left: 0 !important;
  flex-grow: 1;
}

.multi-text-array-label {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.multi-text-array-new-btn {
  height: 33px;
  min-height: auto;
  font-size: $font-size;
}

.multi-text-array-new-btn-has-content {
  @extend .multi-text-array-new-btn;
  color: white;
  background-color: $manz-gray !important;
}

.multi-text-array-delete-btn {
  @extend .multi-text-array-new-btn;

  &:hover {
    color: #fff;
    background-color: $manz-red;
    border-color: $manz-red;
  }
}

.select-array {
  position: relative;
  display: flex;
  flex-wrap: nowrap !important;
  align-items: stretch;
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.maus-over-underline:hover {
  text-decoration: underline
}

.form-control:focus {
  @include activeFocusShadow;
}


.form-group,
.form-row,
.row {
  .beispiel {
    padding-left: 5px;
    padding-right: 5px;
  }
}

select::-ms-expand {
  display: none;
}

.word-break-all {
  word-break: break-all;
}


.pdf-upload {
  flex-grow: 1;
  max-width: calc(100% - 40px);

  .upload-btn {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.separator {
  top: 7px;
}

.fb-aenderungsantrag-info {
  margin-left: 30px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.text-array-titel {
  font-size: 14px;
  margin-top: -7px;

  .add {

  }

  .info {
    margin-top: -5px;
    font-size: 11px;
    margin-left: -11px;
    margin-right: -13px;
    color: #acacac;
  }
}

.manz-block-wrapper {
  border: 1px;
  border-style: solid !important;
  margin-left: 30px;
}

.fb-aenderungsantrag-auszug-btns {
  margin-left: 35px;
}

.fb-aenderungsantrag-basisdaten-layout {
  margin-left: 0px;
  margin-right: 0px;
}

.fb-aenderungsantrag-beiproblem {
  margin-left: 30px;
}

.fb-aenderungsantrag-erneut {
  margin-left: -15px;
}

.fb-struk-anzeigen-btn {
  margin-left: 20px;
  border-radius: 2px;
  background-color: $medium-grey;
  color: black;
  height: 60px;

  > div {
    margin-top: 3px;
  }
}

.fb-funktion-person {
  position: relative;
  right: -10px;
}

.fb-erneut-btn {
  width: 100px;
}

.fb-struk-address-unstruk {
  font-style: italic;
  font-size: 13px;
}


.tst-text {
  margin-top: 5px;
  margin-left: 20px;
}

.manz-underline {
  text-decoration: underline;
}

.allow-space {
  white-space: pre
}

.fb-aenderungsantrag-html {
  border-style: solid;
  border-radius: 3px;
  border-color: $manz-gray-light;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 380px;
  overflow: auto;
}

.loading-with-bottom {
  margin-bottom: 10px;
}

.fb-miteintragung-von-bis {
  margin-bottom: 31px;

  .fb-unternehmedauer-von {
    position: relative;
    left: 15px;
    display: flex;
    margin-bottom: -8px;

    > label {
      margin-right: 10px;
      position: relative;
      top: 2px;
    }
  }

  .fb-unternehmedauer-bis {
    position: relative;
    left: 15px;
    display: flex;
    @-moz-document url-prefix() {
      margin-top: 18px;
      margin-bottom: -25px;
    }

    > label {
      margin-right: 10px;
      position: relative;
      top: 2px;
    }

    > div {
      position: relative;
      left: 4px;
    }
  }
}

.fb-miteintragung-bis-hover {
  > div {
    > div > div > div > input {
      background: white;
    }
  }
}

.text-array-editable-item {
  position: relative;
  left: 18px;
}

.text-array-editable-input {
  background-color: $highlight-color;
}

.fb-drucken-btns {
  > div {
    margin-left: 10px;
  }
}

.collapse-delete-with-reset-img {
  position: relative;
  top: -1px;
}

.fb-visualisierung-hinweis {
  max-width: 516px;
  white-space: pre-line;

  > div {
    max-width: 516px;
    white-space: pre-line;
  }
}

.fb-miteintragung-label {
  position: relative;
  top: -7px;
}

.fb-miteintragung {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: -27px;

  > label:nth-child(n+2) {
    position: relative;
    margin-top: 10px;
    left: -14px;
  }
}

.fb-text-vertretung {
  @-moz-document url-prefix() {
    margin-top: 25px;
  }
}

.fb-vertretungsbefugt-nur-fuer {
  position: relative;
  left: 5px;
}

.vj-exe-fallcode {
  position: relative;
  top: 5px;
}

.fb-visualisierung-pdf-download {
  position: relative;
  left: 4px;
  min-height: 10px !important;

  > span {
    font-size: 13px;
  }
}

.form-error-msg-right {
  text-align: right;
}

.kontakt-basisdaten-plz-hinweis {
  position: relative;
  top: -34px;
}

.mitteilung-an-das-gericht {
  margin-bottom: 20px;
}

.interne-info {
  position: relative;
  top: 6px;
}

.interne-info-bottom {
  margin-bottom: 10px;
}

.fb-verbesserung-titel {
  position: relative;
  left: 18px;
}

.struk-fb-absender-code {
  position: relative;
  top: 4px;
  left: 2px;
}

.text-area-index {
  height: 23px;
  margin-bottom: 1px;
  top: -1px;
  position: relative;
  font-style: italic;
  color: $manz-gray-hover;
  font-size: 13px;
  left: 7px;
}


.text-area-index-extra {
  height: 23px;
  margin-bottom: 1px;
  top: -20px;
  position: relative;
  font-style: italic;
  color: #ae130f;
  font-size: 19px;
  font-weight: bold;
  left: 10px;
}

.text-area-index-label {
  margin-bottom: -12px;
}

.text-area-index-hinweis {
  position: relative;
  top: 9px;
  left: 5px;
  font-style: italic;
  color: $manz-gray-hover;
  font-size: 13px;
}

.text-area-index-beschreibung-hinweis {
  position: relative;
  left: 5px;
  font-style: italic;
  color: $manz-gray-hover;
  font-size: 13px;
  text-align: left;
}

.text-area-pflicht {
  > textarea {
    background-color: $required-bg-color;
  }
}

.text-area-scroll {
  > textarea {
    overflow-y: hidden;
  }
}

.text-area-placeholer {
  ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    white-space: unset;
  }

  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    white-space: unset;
  }

  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    white-space: unset;
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    white-space: unset;
  }

  :placeholder-shown { /* Standard Pseudo-class */
    white-space: unset;
  }
}

.struk-fb-kontakttyp {
  position: relative;
  top: 5px;
}

.struk-fb-euid {
  position: relative;
  top: -29px;
  left: 6px;;
}

.struk-fb-auszug-anzeigen {
  max-height: 500px;
  overflow-y: auto;
}

.struk-fb-auszug-pdf {
  margin-right: 26px;
  position: relative;
  top: -17px;

  > img {
    left: 10px;
    margin-left: 5px;
  }
}

.begehren-literal {
  > div {
    > input {
      background-color: white;
    }
  }
}

.text-algin-center {
  text-align: center;
}

.erv-toast-header {
  position: relative;
  min-height: 0;
  z-index: -1;

  > div {
    position: absolute;
    top: 0px;
    right: 30px;

    > div {
      border-color: $manz-blue-medium;
    }
  }
}

.erv-toast-header-display {
  position: relative;
  min-height: 0;
  z-index: 999;

  > div {
    position: absolute;
    top: 0px;
    right: 30px;

    > div {
      border-color: $manz-blue-medium;
    }
  }
}

.slide-in {
  animation: slide-in 1s linear;
  -webkit-animation: slide-in 1s forwards;
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

.warn-blink {
  color: $manz-red;
  background-color: white;
  border-radius: 4px;

  animation: blink 2s linear infinite;
  -webkit-animation: blink 2s linear infinite;
  -moz-animation: blink 2s linear infinite;
  -ms-animation: blink 2s linear infinite;
  -o-animation: blink 2s linear infinite;
}
@keyframes warn-blink{
  0%{
    opacity: 1;
    background-color: white;
  }
  100%{
    opacity: 0;
    background-color: white;
  }
}
@-webkit-keyframes warn-blink {
  0%{
    opacity: 1;
    background-color: white;
  }
  100%{
    opacity: 0;
    background-color: white;
  }
}
@-moz-keyframes warn-blink {
  0%{
    opacity: 1;
    background: white;
  }
  100%{
    opacity: 0;
    background-color: white;
  }
}
@-ms-keyframes warn-blink {
  0%{
    opacity: 1;
    background-color: white;
  }
  100%{
    opacity: 0;
    background-color: white;
  }
}
@-o-keyframes warn-blink {
  0%{
    opacity: 1;
    background-color: white;
  }
  100%{
    opacity: 0;
    background-color: white;
  }
}

.fb-textbausteine-wrapper {
  display: flex;
  flex-direction: row-reverse;
}

.justizbox-hinweis {
  padding-left: 11px;
  margin-bottom: -11px;
  margin-top: -4px;
}

.justizbox-hinweis-type {
  padding-left: 11px;
}

.eigentumsanteil-zeile-select {
  position: relative;
  top: -2px;
}

.vorbringen-sachverhalt {
  .titel {
    font-size: 16px;
  }
  .hinweis{
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 14px;
  }
}

.vorbringen-kumulierter-antrag {
  .titel {
    margin-bottom: 15px;
    font-size: 15px;
  }
}

.justizbox-upload-progress {
  position: relative;
  top: -12px;

  .titel {
    text-align: center;
  }

  .filename {
    font-size: 11px;
    text-align: center;
    font-style: italic;
    color: $manz-blue;
  }
}

.progress-justizbox-percent {
  position: relative;
  left: 5px;
}

.justizbox-upload-hinweis {
  margin-bottom: 15px;
  margin-top: 7px;
  display: flex;
}
.justizbox-upload-hinweis-text {
  position: relative;
  top: 1px;
  left: 5px;
}

.justizbox-progress-bar {
  margin-top: -41px;
  margin-bottom: -28px;
}

.stru-fb-sitz-nr {

  > div > div > input {
    background-color: white !important;
  }
}

.left-5 {
  position: relative;
  left: 5px;
}

.manz-link {
  color: $manz-blue;
  text-decoration:underline;

  &:hover {
    cursor: pointer;
    color: $manz-red;
    text-decoration:underline;
  }
}

.manz-link-without-underline {

  &:hover {
    cursor: pointer;
    color: $manz-red;
    text-decoration:underline !important;
  }
}

.struk-fb-rts-vorschau {
  margin-top: -16px;
}

.margin-left-7 {
  margin-left: 7px;
}

.margin-left--4 {
  margin-left: -4px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-bottom--18px {
  margin-bottom: -18px;
}

.margin-bottom-20px {
  margin-bottom: 20px;
}

.margin-bottom-30px {
  margin-bottom: 30px;
}

.top-2 {
  position: relative;
  top: 2px;
}

.deprecated-rechtstatsache {
  margin-top: -15px;
  margin-bottom: 15px;
}

.titel-disabled {
  color: $medium-grey;
}

.gb-ergaenzung-hinweis {
  margin-bottom: 30px;
}

.margin-bottom--10px {
  margin-bottom: -10px;
}

.margin-top--10px {
  margin-top: -10px;
}

.margin-top--15px {
  margin-top: -15px;
}

.margin-top-6px {
  margin-top: 6px;
}

.font-size-13px {
  font-size: 13px;
}

.font-size-14px {
  font-size: 14px;
}

.font-size-15px {
  font-size: 15px !important;
}

.font-size-16px {
  font-size: 16px !important;
}

.font-size-17px {
  font-size: 17px !important;
}

.font-size-21px {
  font-size: 21px;
}

.with-225px {
  width: 225px;
}

.with-230px {
  width: 230px;
}

.with--100 {
  width: 100%;
}

.inaktiv-einbringer-header {
  margin-top: -15px;
  margin-bottom: 15px;
}

.ervbox-upload-not-finish {

  .abbrechen {
    margin-top: 21px;
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: -12px;
  }

  .buttons {
    display: flex;
    float: right;
    margin-top: 21px;
    margin-bottom: 20px;
    margin-right: 40px;

    .btn {
      width: 60px;
    }
  }
}

.gb-begehrensfreitext-hinweis {
  margin-bottom: 20px;
}

.gb-urkundenschaerzung-basisdaten-hinweis {
  margin-bottom: 30px;
  margin-left: 28px;

  .details {
    margin-left: 33px;
  }
}

.gb-urkundenschaerzung-basisdaten-tagebuchzahl {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 25px;

  .li-hinweis {
    margin-left: 52px;
  }
}

.gb-urkundenschaerzung-basisdaten-tz-gericht {
  margin-left: 7px;
  margin-top: -5px;
}

.gb-urkundenschaerzung-basisdaten-tz-gericht-item {
  margin-left: 93px;
}

.gb-urkundenschaerzung-basisdaten-tz-gericht-item-2 {
  margin-left: 85px;
}

.gb-tz-spacer {
  margin-left: 3px !important;
  margin-right: 4px !important;
}

.gb-tz-tagebuchzahl {
  width: 120px;
}

.gb-tz-gericht {

  .label {
    margin-left: 3px;
  }

  .gericht {
    width: 422px;
  }

  .btn {
  }
}

.gb-tz-gericht-inputs {
  margin-top: 22px;
}

.gb-tz-gericht-btns {
  margin-left: 10px;
}

.required-color {
  background-color: $required-bg-color;
}

.external-link {
}

.external-link-icon {
  position: relative;
  top: -2px;
}

.display-block {
  display: block !important;
}
