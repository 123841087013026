.newsModal-btns {
  margin-right: 5px;
  margin-top: -15px;
  margin-bottom: 25px;
  @media(max-width: 600px) {
    display: flex;
    flex-direction: column;
  }
}

.newsModal-save-btn {
  position: relative;
  left: 6px;
  top: -6px;
  @media(max-width: 600px) {
    position: relative;
    top: 0px;
    left: -5px;
  }
}

.newsModal-btns-label {
  position: relative;
  left: 15px;
  top: 37px;
  @media(max-width: 600px) {
    position: relative;
    top: 0px;
    left: 10px;
  }
}

.newsModal-btns-check {
  position: relative;
  left: 2px;
  top: -1px;
}

.newsModal-beispiel {
  font-style: italic;
}

