// Importing Variables from Main Project
@import '../Main/styles/settings/colors';
@import '../Main/styles/settings/variables';
@import '../Main/styles/settings/spacings';
@import '../Main/styles/settings/fonts';
@import '../Main/styles/tools/animations';
@import '../Main/styles/tools/mixins';

$table-width: 800px;

.homeContent{
  /* IE11 CSS styles - workaround for fixed menu */
  _:-ms-fullscreen, :root & {
    margin-top: 112px;
    @include tablet {
      margin-top: 87px;
    }
  }
}

.homeSection {
  padding: 50px;
  min-height: calc(100vh - 110px);
  display: flex;
  align-items: center;
  position: relative;
  h1 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .scrollDown {
    bottom: 20px;
    left: calc(50% - 35px);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    &:hover {
      transform: translateY(5px);
    }
  }

  @media (min-width: $break-md) {
    .scrollDown {
      position: absolute;
    }
  }
}

@media (max-width: $break-lg) {
  .homeSection {
    padding: 20px;
  }
}

.scrollUp {
  background-color: $manz-gray-medium;
  border-radius: 50%;
  width: $iconScrollTopSize;
  height: $iconScrollTopSize;
  position: sticky;
  bottom: $spacing-xl;
  margin-bottom: $spacing-xl;
  left: calc(100% - #{$spacing-xl} - #{$iconScrollTopSize});
  z-index: 1;
  display: flex;
  align-items: center;
  text-align: center;
  opacity: 1;

  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  a {
    padding: 5px;
    display: flex;
  }

  svg {
    width: 100%;
    height: 100%;
    polyline {
      stroke: $white;
    }
  }

  &:hover {
    transform: translateY(-5px);
  }
  /* IE11 CSS styles go here */
  _:-ms-fullscreen, :root & {
    position: fixed;
    bottom: 130px;
  }
}

.section {
  padding: 100px 10px;
}

.sectionBorderTop {
  border-top: 2px solid $manz-gray-light;
}

.sectionWhite {
  background-color: white;
}

.red, a {
  text-decoration: none;
  color: $red;
}

.sectionLightGrey {
  background-color: $bg-color;
}

.sectionGradient {
  background-image: linear-gradient($bg-color, white);
}

.logoContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.videoContainer{
  max-width: 800px;
  width: 100%;
  height: auto;
}

.center {
  text-align: center;
}

.bold {
  font-weight: 500;
}

.additionalInfo {
  @include font-size-rem($sm-font-size);
}

.banner {
  @include font-size-rem($xxl-font-size);
  padding: 50px 0;

  .btn {
    padding-left: 2em;
    padding-right: 2em;
    max-width: 250px;
    -webkit-appearance: none;
  }
  @include tablet{
    @include font-size-rem($lg-font-size);
    .btn {
      margin-top: 2em!important;
    }
  }
}

.bannerBlue {
  background-color: #ecf1fb;
  color: $text-color;
  font-weight: 400;
}

.bannerRed {
  @include font-size-rem($xxl-font-size);
  background-color: $manz-red;
  color: $white;
  font-weight: 400;
  padding: $spacing-xs;
}

.sectionGrey {
  background-color: $manz-gray-dark;
  color: $white;

  .heading-2, .heading-3, .paragraph {
    color: white;
  }
}

.paragraphCenter {
  text-align: center;
}

.heading-2 {
  @include font-size-rem($xxxl-font-size);
  font-weight: 500;
  color: $text-color;
  text-align: center;
  margin: 0 0 40px 0;
}

.heading-contact {
  margin-bottom: 0;
}

.heading-3 {
  @include font-size-rem($xxl-font-size);
  font-weight: 500;
  color: $text-color;
  margin-top: 10px;
}

.subheader {
  text-transform: uppercase;
  text-align: center;
  color: $manz-gray-medium;
  @include font-size-rem($bg-font-size);
  font-weight: 500;
  letter-spacing: 0.1em;
  margin-bottom: 20px;
}

.subheaderBlue {
  color: $blue;
  @include font-size-rem($lg-font-size);
  font-weight: 500;
  display: flex;
}

.paragraph {
  color: $text-color;
  @include font-size-rem($bg-font-size);
  line-height: 1.4rem;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

.paragraphLarge {
  @extend .paragraph;
  @include font-size-rem($xl-font-size);
  color: $text-color;
  width:100%;
}
.paragraphBig {
  @include font-size-rem($lg-font-size);
  color: $text-color;
}

.badgeSmall {
  @include border-radius(50px);
  background-color: $bg-color;
  color: $text-color;
  @include font-size-rem($xxxs-font-size);
  font-weight: 500;
  display: inline-block;
  padding: 3px 10px;
  text-transform: uppercase;
  letter-spacing: 0.04rem;
  line-height: 1.1;
}

.unstyled {
  list-style: none;
  padding: 0;

  .paragraphBig{
    padding: 0 25px;
  }
}

.headingInfoSite {
  @include font-size-rem($xxxl-font-size);
  font-weight: 500;
  color: $text-color;
  margin: 20px 0;
  padding-bottom: 25px;
  border-bottom: 3px solid $manz-gray-medium;
}

.list {
  margin: 0;
  padding-left: 20px;
}
.navigationInfoSite {
  li {
    color: $red;
    padding: $spacing-xs 0;
  }
}

.sectionInfoSite {
  padding: 20px 0 100px 0;
}

.listItemInfoSite {
  @extend .heading-3;
  margin-top: 25px;

  .paragraph {
    font-weight: normal;
  }

  h2 {
    margin-top: 25px;
  }
}

@media (max-width: $break-md) {
  .centerMobile {
    text-align:center;
  }
}

.tableWrapper{
  display: flex;
  justify-content: center;
  align-items: center;

  @include phone() {
    justify-content: flex-start;
    overflow: auto;
  }

  table {
    th {
      border-top: 1px solid transparent !important;
      text-align: center;
      font-size: $xl-font-size;

      &.checkColumn{
        width:15%;
      }
    }
    tr {
      &:nth-child(even) {
        background: $manz-gray-light;
      }
      &:nth-child(odd) {
        background: $white;
      }
      td{
        border: 1px solid $manz-gray-medium;
      }
    }
    @include phone(){
      padding: 5px;
    }
  }

  .topOfferWrapper{
    position: relative;
    width: fit-content;
    margin: auto;
    .topOffer{
      position: absolute;
      right: -25px;
      top: -15px;
      width: 25px;
      height: 25px;
      margin: 0;
/*      @include phone(){
        right: -5px;
        top: -5px;
        width: 20px;
        height: 20px;
      }*/
    }
  }

  .icon {
    height: 25px;
    width: 25px;
    margin: auto;
  }

  .cross svg{
     width: 20px;
     height: 20px;
   }
}

.hidden {
  opacity: 0;
}
