$options-height: 32px;
$options-color: hsl(0, 0%, 30%);
$options-bg: #DEEBFF;
$options-bg-active: #B2D4FF;

.select-wrapper {
  .contact-container & {
    width: 700px;
  }
  border: 1px solid $manz-gray-medium;
  max-height: 300px;
  overflow: auto;
  margin-top: -1px;
  max-width: 90vw;

  .kontaktContextSpaltHeader {
    color: $manz-red;
    font-weight: bold;
    margin-top: $spacing-xs;
  }

  .kontaktContextSpaltHeader,
  .kontaktContextSpalt {
    padding: 0 3px;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;

    &.name {
      flex-grow: 1;
      max-width: 50%;
    }
    &.kuerzel {
      width: 15%;
    }
    &.plz {
      width: 15%;
    }
    &.ort {
      width: 20%;
    }
  }
}

.selectOptionContainer {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  width: 100%;

  .optionsHeader {
    padding: 0 8px;
    background-color: white;
    height: $options-height;
    display: inherit;
  }

  .options {
    padding: 0 8px;
    background-color: white;
    border: none;
    text-align: left;
    min-height: $options-height;
    color: $options-color;
    display: flex;
    align-items: center;

    &.header {
      cursor: default;

      &:hover {
        background: $manz-white;
      }
    }

    &:active {
      background-color: $options-bg-active !important;
    }

    &:focus {
      outline: none;
      background-color: $options-bg;
    }

    &:hover {
      background: $options-bg;
    }
  }
}

.optionsInfo {
  padding: 10px;
  background-color: white;
  border: none;
  text-align: center;
  color: $manz-blue;

  &.no-entries {
    display: none;
    .contact-container & {
      color: $text-color;
      display: block;
    }
  }
}

.context {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  .manz-editor & {
    width: 99%;
  }
}

.loadingSpinnerWrapper {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  .spinner {
    position: absolute;
    right: 10px;

    &.spacingRight {
      right: 40px;
    }
  }
  .dropDownIcon {
    position: absolute;
    right: 8px;
    top: 8px;
  }
}


// from react select

.input-text, input[readonly] {
  border: 1px solid $manz-form-border-color;
  padding: $spacing-xxs 8px;
  width: 100%;
}
.input-text-disabled, input[readonly] {
  border: 1px solid $medium-grey;
  padding: $spacing-xxs 8px;
  width: 100%;
}

.react-select-container-multi {
  width: 100%;
  letter-spacing: 0.3px;

  .react-select__control {
    @include border-radius(0 !important);
    border-color: $manz-form-border-color;
    min-height: 30px !important;
    height: auto;

    &--is-disabled{
      background-color: $manz-bg-disabled!important;
      border-color: $manz-form-border-color!important;
    }

    input {
      border: none!important;
    }
  }

  .react-select__menu {
    z-index: 1040;
  }

  .react-select__multi-value {
    position: relative;

    .react-select__multi-value__label {
      position: relative;
      margin-left: 3px;
      margin-right: -1px;
      top: 1px;
    }
  }
}

.react-select-container {
  width: 100%;
  letter-spacing: 0.3px;

  .react-select__menu {
    z-index: 1040;
  }
}

.react-select-icon-custom {
  background-image: url('../../../assets/icons/mz-arrow-down.svg');
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
}

.react-select__control {
  @include border-radius(0 !important);
  border-color: $manz-form-border-color;
  min-height: 30px !important;
  height: 33px;

  &--is-disabled{
    background-color: $manz-bg-disabled!important;
    border-color: $manz-form-border-color!important;
  }

  input {
    border: none!important;
  }
}

.react-select__control--is-focused {
  @include activeFocusShadow;
}

.react-select__indicator {
  color: $manz-gray-medium !important;
  padding: 0 8px;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__dropdown-indicator {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border: none !important;
}

.react-select__menu {
  border-radius: 0 !important;
  border-color: $manz-form-border-color!important;
  margin-top: 0 !important;
  background-color: transparent;
}

.react-select__option{
  &:hover,
  &--is-selected,
  &--is-selected:hover {
    background-color: $manz-blue-light;
    color: $manz-gray !important;
  }
}

.gb-auszug-beteiligter-rolle-select {
  width: 122px;
  margin-left: 10px;
  margin-top: 2px;
}

.gb-auszug-list-options {
  margin-left: -10px;

  .titel {
    font-size: 16px;
  }

  .select {
    width: 306px;
    margin-left: 23px;
    margin-top: -6px;
  }
}
