@import '../settings/colors';

.tabs-begehren {
  border-style: none;
}

.begehren-content {
  min-height: 300px;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  border-top-width: 0;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: 0;
  padding: 15px;
  border-color: $manz-tabs-grey;
}

.begehren-new {
  min-height: 220px;
}

.begehren-titel {
  margin-left: 5px;
  margin-bottom: 12px;
}

.begehren-paragraph-title {
  margin-left: $spacing-sm;
  margin-top: $spacing-sm;

  @media(max-width: $break-md){
    margin-left: 0;
    margin-top: $spacing-md;
  }
}

.begehren-custom-text-array {
  position: relative;
  margin-bottom: 10px;
}

.begehren-radio-button {
  position: relative;
  margin-right: 0 !important;
}


.vertical-radio-button-options {
  position: relative;
  margin-bottom: $spacing-sm;

  > div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .form-check-label {
    height: auto;
    min-height: 25px;
    width:inherit;
    white-space: normal;
  }
  .flex-column .form-check-label {
    &:not(:first-of-type){
      margin-top: $spacing-sm;
      margin-left: 0;
    }
    &:first-of-type{
      margin-top: $spacing-xxs;
    }
  }
}


.begehren-anderes-begehren-help {
  position: relative;
  left: 30px;
  top: 10px;
}

.begehren-anderes-begehren-array-help {
  position: relative;
  left: 30px;
  top: 45px;
}

.begehren-pfandrecht-bezug-anderes-begehren-label {
  position: relative;
  top: 22px;
}

.begehren-pfandrecht-bezug-anderes-begehren-help {
  position: relative;
  left: 24px;
  top: 29px;
}

.begehren-eigentumsanteile-help {
  position: relative;
  left: 106px;
  top: 4px;
}

.begehren-eigentumsanteile-with-vormerkte-eigentumemer-help {
  position: relative;
  left: 34px;
}

.begehren-berechtigte-person-help {
  position: relative;
  left: 34px;
  top: 44px;
}

.begehren-we-zeilen-content {
  min-height: 220px;
}

.begehren-we-zeile-content {
  border-width: 2px;
  border-color: $manz-collapse-border-in;
  //background-color: $manz-collapse-background-in;
  @include box-shadow(0px 0px 7px 0px $manz-collapse-shadow-out);
}

.styled-table-entry {
  input:disabled,
  .react-select-container,
  .react-select-container * {
    background-color: transparent!important;
    border: none;
    color: $text-color;
    padding: 0;
  }
  .react-select__indicators{
    display:none;
  }
  button.icon-button,
  .react-select__control {
    border: none !important;
  }
}

.styled-table-entry .styled-table-border,
.styled-table-border{
  border-top: 1px solid $manz-gray-medium;
  padding: $spacing-xxs 0 $spacing-xxs $spacing-md;

  border-bottom: 1px solid #c0c0c0;
  margin-top: -1px;
  margin-bottom: 0;
  .btn-wrapper {
    width: 100px;
    text-align: center;
    .btn-secondary{
      width: inherit;
    }
  }
}

.begehren-sonstige-glaeubiger-clnr {
  position: relative;
  left: -5px;
}

.begehren-new-uh {
  margin-right: -8px;
}

.begehren-loeschung-neueintragungsreferenz {
  margin-left: -5px;
}

.begehren-loeschung-titel {
  margin-left: 24px;
}

.begehren-list-veraltet-info {
  top: 7px;
  left: 5px;
  font-weight: bold;
  position: relative;
  color: $manz-blue;
}

.begehren-list-veraltet-delete {
  background-color: $manz-blink-red;
  border-radius: 3px;

  &-blink {
    @extend .begehren-list-veraltet-delete;
    animation: changeshadow 2s ease-in infinite;
    -webkit-animation: changeshadow 2s linear infinite;
    -moz-animation: changeshadow 2s linear infinite;
    -ms-animation: changeshadow 2s linear infinite;
    -o-animation: changeshadow 2s linear infinite;
  }
}

.blink{
  animation: blink 7s infinite;
}
@keyframes blink{
  0% {
    background: $manz-collapse-shadow-out;
  }
  30% {
    background: transparent;
  }
}

@-webkit-keyframes blink{
  0% {
    background: $manz-collapse-shadow-out;
  }
  30% {
    background: transparent;
  }
}

