@import '../settings/spacings';
@import '../settings/colors';

table {
  width: 100%;

  thead {
    tr {
      border: none;
    }
    th {
      margin-right: 1px;
      background-color: $manz-white;
      border: none;
      position: sticky;
      top: 0;
      user-select: none;
      white-space: nowrap;
    }
  }

  th, td {
    padding: $spacing-sm;
  }

  tr {
    cursor: pointer;
    border-top: 1px solid $table-border-color;
    border-bottom: 1px solid $table-border-color;
    &:nth-child(even) {
      background-color: lighten($manz-gray-light, 10%);
    }
  }

  tr:hover {
    background-color: $table-hover-color;
  }
}

.table thead th {
  border: none;
}
.table-paging {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: $spacing-md 0;
}

.table-container {
  .nr {
    width: 60px;
  }
  .status {
    width: 160px;
  }
  .date {
    width: 140px;
  }
  .art {
    min-width: 30%;
  }
  .einbringer {
    width: 330px;
  }
}

.margin-table-top {
  margin-top: -30px;
}

.download-col{
  width: 120px;
}


.responsive-table{
  .block {
    border-bottom: 1px solid $manz-form-border-color;
    padding-top: $spacing-xs;
    padding-bottom: $spacing-xs;
    @extend .cursor-pointer;
  }
  .entry{
    margin-bottom: $spacing-xxs;
    display: inline-block;
    white-space: pre;
    width: auto;
  }

  .date {
    margin-right: $spacing-md;
  }

  .copy {
    .icon-button{
      height: 25px;
      min-height: 25px;
    }
  }

  .aktenzeichen {
    min-width: 60%;
  }

  .gericht {
    @extend .w-100;
  }
}

.draggable {
  cursor: move;
  z-index: 0;
}

.veraltet-row {
  color: $manz-gray-medium
}

.veraltet-row-index {
  color: $manz-red;
}


.protokoll-text {
  word-break: break-word;
}

.table-with-border {
  border-left: 1px solid $manz-gray-light;
  border-right: 1px solid $manz-gray-light;
}

.table-with-header {
  background-color: $manz-gray-light;
  font-weight: bold;
}

.table-gb-auszug-header {
  background-color: $manz-gray-medium;
  z-index: 999;
}

.table-disable-row {
  color: $manz-gray-medium;
  cursor: context-menu;
}
