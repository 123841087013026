@import '../settings/colors';


.rolleStyle {
  display: flex;
  max-width: $content-width;
  width: 100%;
  flex-direction: row;
  margin: auto;
  justify-content: space-between;

  .navbar {
    width: $navbar-width;
    min-width: $navbar-width;
    background-color: $manz-white;
    margin-right: $spacing-xs;
    display: block;
    @extend .noselect;
  }

  .content {
    background-color: $manz-white;
    width: 100%;
  }
}

.hilfeContentStyle {
  max-width: 970px;
  min-height: 867px;
}

#root {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height:100vh;
  width:100%;
  background-color: $bg-color;

  .content {
    flex-grow: 1;
  }
  .layout{
    margin-bottom: $spacing-lg;
  }
}

.no-wrap {
  white-space: nowrap;
}

.text-align-md-center{
  @media(max-width: $break-md){
    text-align: center;
  }
}

.sticky {
   position: fixed;
   position: -webkit-sticky;
   position: sticky;
   top: 0;
   right: 0;
   left: 0;
   z-index: 16;
 }

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

::-moz-selection {
  background: $call-to-action-blue;
  color: $white;
}
::selection {
  background: $call-to-action-blue;
  color: $white;
}

:focus {
  outline: $outline-color auto 1px;

}
button:focus {
  outline: transparent!important;
}

input:disabled::placeholder,
.react-select--is-disabled .react-select__placeholder {
  color: transparent!important;
}



.vertical-align-center{
  vertical-align: center;
}

.height-auto{
  height: auto;
}

.cursor-default {
  cursor: default;
}

.mz-alert-info {
  margin-bottom: 23px !important;
  margin-top: 3px;
  color: $manz-blue;

  > div {
    justify-content: start !important;
  }
}

.white-space-normal {
  white-space: normal;
}
