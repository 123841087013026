@import '../settings/colors';

.manz-red, .error {
  color: $manz-red;
}

.manz-blue {
  color: $manz-blue;
}

.manz-gray {
  color: $manz-gray-hover;
}

.manz-sub-label-color {
  color: #9d9999;
}

.manz-black {
  color: #666;
}

.manz-disabled {
  color: $manz-text-disabled!important;
  pointer-events: none!important;
}

.isRequired,
.isRequired input,
.isRequiredWrapper .react-select__control {
  @include isRequired;
  &:hover{
    @include isRequired;
  }
}

.beispiel {
  color: $manz-blue;
}

.differenzRed {
  color: $manz-schriftsatz-fehler;
}

.differenzGreen {
  color: $manz-green;
}

.warning {
  white-space: pre;
  color: $manz-orange;
}

.bg-white {
  background-color: $manz-white;
}

.bg-color-lightblue {
  background-color: $manz-blue-light;
}

.sm-font-size {
  font-size: $sm-font-size;
}

.xs-font-size {
  font-size: $xs-font-size;
}

.xxs-font-size {
  font-size: $xxs-font-size;
}
