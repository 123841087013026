@import '../settings/colors';

.btn {
  @include border-radius(0px);
  color: $text-color;
  background-color: $manz-gray-light;
  padding: .375rem .75rem;
  min-height: 36px;
  height: 36px;  // for ie11
  height: fit-content;
  border:none;

  &:not(:first-of-type) {
    margin-left: 15px;
  }
  .svg-icon {
    stroke: $text-color;
  }
  &:hover {
    background-color: $manz-gray-medium-hover;
    .svg-icon{
      stroke: $manz-gray-light;
    }
  }

  .form-group &,
  .input-group & {
    border: 1px solid $manz-form-border-color;
    font-size: 14px;
    min-height: 33px;
    max-height: 33px;
  }
  &.fixed-height-33 {
    min-height: 33px;
    max-height: 33px;
    height: 33px;
  }
}


/* primary, secondary and tertiary buttons are just for coloring */
.btn-primary {
  color: $manz-gray-light;
  background-color: $text-color;
  &:hover, &:active {
    background-color: $manz-gray-hover;
  }
  .svg-icon {
    stroke: $manz-gray-light;
  }
  &:not(:disabled){
    @include coloredSVG($white);
  }
}

.btn-secondary {
  background-color: $manz-gray-light;
  &:hover, &:active, &.active {
    &:not(:disabled):not(.disabled){
      background-color: $manz-gray-medium-hover;
    }
  }
}

.rc-collapse-header .icon-button:not(.btn-danger),
.table-container .icon-button:not(.btn-danger),
.btn-tertiary {
  background-color: transparent;
  border: none;
  color: $text-color;
  @include coloredSVG($text-color);

  &:hover {
    background-color: $manz-gray-medium-hover;
  }
}

.icon-button {
  $icon-width: 25px;

  min-width: 35px;
  min-height: 36px;
  height: 36px; // for ie11
  height: fit-content;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 .4rem;

  .icon, img, svg {
    width: $icon-width;
    height: $icon-width;
    min-width: $icon-width;
    min-height: $icon-width;

    & + .label {
      margin-left: $spacing-xs;
    }
  }
  .label {
    margin-right: $spacing-xs;
  }

  &.btn-sm,
  .form-group &,
  .input-group & {
    min-height:33px;
    min-width:33px;
  }
}

.btn-danger {
  border-color: transparent;
  background-color: transparent;
  &:hover {
    border-color: $manz-red;
    background-color: $manz-red;
    &:not(:disabled){
      @include coloredSVG($white);
    }
  }
}


.btn:disabled {
  background-color: transparent!important;
  color: $manz-gray-medium!important;
  opacity: $opacity-disabled!important;
  cursor: default!important;
}

.button-wrapper {
  display: flex;
  .icon-button {
    padding: 0;
  }
}

.btn-sm {
  .input-group &,
  .form-group & {
    height:33px;
    min-height:33px!important;
  }

  .svg-icon, svg {
    height: 19px;
    width: 19px;
  }

  max-height: 33px;
}

.button-wrapper-right {
  float: right;
}


.btn-blue {
  background-color: $blue;
  color: $white;

  &:disabled {
    background-color: $manz-gray-medium;
    color: $manz-gray;
  }

  &:hover:not(:disabled) {
    background-color: darken($blue, 10%);
    color: $white;
  }
}

.new-button {
  width: 50px;
}

.weiterleiten-button {
  margin-right: 5px;
}

.weiterleiten-button-spinner {
  position: relative;
  left: 40px;
  top: 5px;
}

.save-button-spinner {
  position: relative;
  left: 40px;
  top: 5px;
}

.center-spinner-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;

  .center-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 99999;
    transform: translate(-50%, -50%);
  }
}

.fbauszug-button {
  margin-top: -8px;

  .second-text {
    margin-top: -5px;
  }
}

.double-text-array-extra-btn {
  width: 109px;
  display: flex;
}

.gbauszug-blnr-literal-list-btn {
  width: 214px;
  margin-left: 3px;
}

.fb-textbausteine-btn {
  display: flex;
  width: 145px;

  .titel {
    margin-left: 3px;
  }
}
