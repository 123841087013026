.rc-collapse {
  border-radius: 0;
  &.content-overflow-visible {
    border-left: 0 white !important;
    border-right: 0 white !important;
    .rc-collapse-item {
      background-color: white;
      .rc-collapse-header {
        display: block;
        padding: $spacing-xxs $spacing-md;
      }
      &.rc-collapse-item-active {
        @include box-shadow(0 0 7px 0 $manz-collapse-shadow-out);
        margin-bottom: 3px;

        >.rc-collapse-header {
          display: block;
          padding: $spacing-xs $spacing-md;
          background-color: $manz-collapge-header-bg;
          font-weight: bold;
        }
        .rc-collapse-content {

          >.rc-collapse-content-box {
            margin: 0;
            padding: $spacing-md 0;
          }

          &.rc-collapse-anim {
            overflow: hidden !important;
          }

          &.rc-collapse-content-active {
            overflow: visible;
          }
        }
        &:hover{
          background-color: white;
          @include box-shadow(0 0 7px 0 $call-to-action-blue);
          margin-bottom: 3px;
        }
      }
    }
    .rc-collapse-item:hover {
      background-color: $table-hover-color;
    }
  }

  // inner blue collapse
  &.content-overflow-visible-blue {
    border-width: 1px;
    border-color: $manz-collapse-border-in;
    .rc-collapse-item {
      background-color: $manz-collapse-background-in;
      &.rc-collapse-item-active {
        @include box-shadow(0 0 7px 0 $manz-collapse-shadow-out);
        margin-bottom: 3px;

        .rc-collapse-content {
          background-color: $manz-collapse-background-in;
        }
        &:hover{
          background-color: $manz-collapse-background-in;
          @include box-shadow(0 0 7px 0 $call-to-action-blue);
          margin-bottom: 3px;
        }
      }
      &:hover {
        background-color: $table-hover-color;
      }
    }
  }

  .help-icon  {
    margin-top: auto;
  }
}
