@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes fadeIn1 {
  0%   { opacity: 0;}
  0%   { opacity: 0;}
  80%   { opacity: 1; }
}

@keyframes fadeIn2 {
  0%   { opacity: 0;}
  10%   { opacity: 0;}
  90%   { opacity: 1; }
}

@keyframes fadeIn3 {
  0%   { opacity: 0;}
  20%   { opacity: 0;}
  100%   { opacity: 1; }
}