@import '../settings/colors';
@import '../settings/fonts';

.manz-modal {
  z-index: 1051;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: white;
  min-width: 800px;
  max-width: 1200px;

  @media screen and (max-width: map-get($grid-breakpoints, "lg")) {
    min-width: 90%;
    max-height: 100%;
    overflow: auto;
  }

  .manz-modal-header {
    margin: 20px 25px 0 25px;
    padding-bottom: 10px;
    border-bottom: 1px solid $medium-grey;
    font-size: $font-size-h1;
    color: $manz-red;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .close-btn {
      background: transparent;
      border: none;
      width: 20px;
      height: 20px;
      cursor: pointer;
      padding: 0;

      img {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .manz-modal-body {
    padding: 25px;
    white-space: pre-line;
    $dt-width: 120px;

    .contact {
      dt {
        font-weight: normal;
        float: left;
        width: $dt-width;
      }
    }

    .modal-row {
      display: flex;
      align-items: center;
      padding: 5px;

      .modal-label {
        width: 70px;
      }
    }
  }

  .manz-modal-footer {
    padding: 15px;
    background-color: $manz-gray-light;
    display: flex;
    justify-content: flex-end;

    button {
      margin-left: 5px;
    }
  }
}

.manz-modal-help-body {
  margin-top: 1px !important;
  height: 631px;
  overflow-y: scroll;
  margin-bottom: 6px;
}

.manz-modal-news-body {
  max-height: 468px;
  @media(max-height: $break-sm) {
    max-height: 310px;
  }
  @media(max-height: $break-xs) {
    max-height: 210px;
  }
}

.gb-auszug-modal-body {
  padding: 25px;
  white-space: pre-line;
  $dt-width: 120px;

  .contact {
    dt {
      font-weight: normal;
      float: left;
      width: $dt-width;
    }
  }

  .modal-row {
    display: flex;
    align-items: center;
    padding: 5px;

    .modal-label {
      width: 70px;
    }
  }
}

.gb-auszug-modal-content-body {
  min-width: 1100px;
  max-height: 960px;
  overflow-y: scroll;
  @media(max-height: $break-md) {
    max-height: 580px;
  }
  @media(max-height: 796px) {
    max-height: 370px;
  }
  @media(max-height: $break-xs) {
    max-height: 210px;
  }
}
