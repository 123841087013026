dl {
  &.documents {
    dt {
      float: left;
      width: 50%;
      margin-right: 0.5em;
      opacity: .6;
    }
    dd {
      width: 50%;
      margin-left: 50%;

      div {
        height: inherit;
      }
    }
  }
}
