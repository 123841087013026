@import url("https://resource.manz.at/fonts/Roboto/roboto.css");
$font-family: Roboto, "Helvetica Neue", Helvetica, Arial, Verdana, sans-serif;

// Font size for home uses base font fize from RDB
$base-font-size: 15px;
$xxxxl-font-size: $base-font-size * 4;
$xxxl-font-size: $base-font-size * 2.5;
$xxl-font-size: $base-font-size * 1.69;
$xl-font-size: $base-font-size * 1.3;
$lg-font-size: $base-font-size * 1.2;
$bg-font-size: $base-font-size * 1.1;
$md-font-size: $base-font-size;
$sm-font-size: $base-font-size * 0.9;
$xs-font-size: $base-font-size * 0.8;
$xxs-font-size: $base-font-size * 0.7;
$xxxs-font-size: $base-font-size * 0.6;
$xxxxs-font-size: $base-font-size * 0.125;


$font-size-xl: 24px;
$font-size-h1: 24px;
$font-size-h2: 18px;
$font-size-nav-top: 16px;
$font-size: 14px;
$font-size-12: 12px;
