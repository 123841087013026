
$drag-n-drop-bg: $highlight-color;
$drag-n-drop-border: $manz-collapse-border-in;
$drag-n-drop-border-hover: $manz-blue;

.dropzone {
  margin: $spacing-lg 0;
  padding: $spacing-md;

  transition: border-color 0.2s;

  .dropzoneLogoTitel {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dropzoneDetails {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > div {
      font-size: 13px;
    }
  }

  .dropzoneProgress {
    display: flex;
    align-items: center;
    justify-content: center;

    .fileSize {
      font-weight: bold;
      font-size: 12px !important;
      position: relative;
      left: -7px;
    }

    .perzent {
      margin-left: 10px;
      font-size: 12px;
      font-weight: bold;
    }

    .perzentStandard {
      margin-left: 10px;
      font-size: 12px;
      font-weight: bold;
      position: relative;
      top: -6px;
    }
  }

  .dropzoneProgressDetails {
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      font-size: 12px;
      font-weight: bold;
    }
  }

  .dropzoneInfo {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: flex;
    h2 {
      margin-bottom: 0;
    }
  }
}

.dropzoneDisabled {
  border: 3px dashed $manz-gray-light;
  background-color: transparent;
  color: $manz-gray-medium;
}

.dropzoneEnabled {
  border: 3px dashed $drag-n-drop-border;
  background-color: $drag-n-drop-bg;
  &:hover {
    border-color: $drag-n-drop-border-hover;
    transition: border-color 0.2s;
  }
}

.dokument-dropzone-layout {
  display: flex;
  justify-content: space-around;

  .left {
    margin-right: 3px;
  }

  .right {
    margin-left: 3px;
  }
}

.dropzone-with-justizbox {
  margin: $spacing-lg 0;
  padding: $spacing-md;
  min-height: 253px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 1258px) {
    min-height: 230px;
  }

  transition: border-color 0.2s;

  .dropzoneLogoTitel {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dropzoneDetails {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 600px;

    > div {
      font-size: 13px;
    }
  }

  .dropzoneProgress {
    display: flex;
    align-items: center;
    justify-content: center;

    .fileSize {
      font-weight: bold;
      font-size: 12px !important;
      position: relative;
      left: -7px;
    }

    .perzent {
      margin-left: 10px;
      font-size: 12px;
      font-weight: bold;
    }

    .perzentStandard {
      margin-left: 10px;
      font-size: 12px;
      font-weight: bold;
      position: relative;
      top: -6px;
    }
  }

  .dropzoneProgressDetails {
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      font-size: 12px;
      font-weight: bold;
    }
  }

  .dropzoneInfo {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: flex;
    h2 {
      margin-bottom: 0;
    }
  }

  .entgeltbestimmungen {
    margin-top: 6px;
  }
}

.dropzoneDisabled {
  border: 3px dashed $manz-gray-light;
  background-color: transparent;
  color: $manz-gray-medium;
}

.dropzoneEnabled {
  border: 3px dashed $drag-n-drop-border;
  background-color: $drag-n-drop-bg;
  &:hover {
    border-color: $drag-n-drop-border-hover;
    transition: border-color 0.2s;
  }
}

.pdfIcon {
  height: 50px;
  width: 50px;
  margin-right: $spacing-lg;
}

.dropzone {
  &:disabled,
  &.disabled {
    &:focus {
      outline: none!important;
    }
    svg {
      .st0, .st1 {
        stroke: $manz-gray-medium;
      }
      .st1, .st2 {
        fill: $manz-gray-medium;
      }
    }
  }
}
