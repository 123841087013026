@import './settings/colors';
@import './settings/variables';

// Add bootstrap adaptions here
// i.e. override colors, paddings, margins etc.


$input-border-radius: 0;
$input-border-radius-lg: 0;
$input-border-radius-sm: 0;
$form-group-margin-bottom: 0.5rem;

$primary: $manz-gray;

$secondary: $manz-gray-medium;

$input-disabled-bg: $manz-gray-light;
$input-border-color: $manz-gray-medium;

//carousel
$carousel-indicator-width:           12px;
$carousel-indicator-height:          12px;
$carousel-caption-color: white;
$carousel-indicator-spacer: 7px;

// Importing Bootstrap
@import '../../../node_modules/bootstrap/scss/mixins';
@import '../../../node_modules/bootstrap/scss/bootstrap-grid';
@import '../../../node_modules/bootstrap/scss/forms';
@import '../../../node_modules/bootstrap/scss/carousel';

.carousel.slide {
  padding-bottom: 60px;
}
.carousel-item {
  min-height: 350px;
  align-items: center;
  text-align: left;
 @media(max-width: $break-xs){
   height: 810px;
  }
}

.carousel-caption {
  text-align: left;
}

.carousel-indicators > li {
  border-radius: 50%;
  border: 1px solid $manz-gray-medium!important;
  background: white;
  &.active {
    background: $manz-gray-medium;
  }
}
.carousel-control-next, .carousel-control-prev {
  color: transparent!important;
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-image: none;
  }
}

.contact-form{
  .form-control {
    border: 0;
  }
}
