@mixin tooltip() {
  @include box-shadow(0 0 14px 0 rgba(0, 0, 0, 0.15));
  display: none;
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 20px;
  background: white;
  color: $text-color;
}

.error-status-tooltip-container {
  .error-status-tooltip {
    @include tooltip();
    padding: $spacing-lg;
    min-width: 33vw;
    max-width: 100vw;
    text-align: left;
    font-size: $font-size;
    white-space: normal;
    line-height: 1.5;

    .badge-in-list & {
      right: 0;
      left: unset;
    }
  }

  &:hover, &:active {
    cursor: pointer;
    .error-status-tooltip {
      display: block;
    }
  }
}


.nav-icon {
  .submenu {
    @include tooltip();
    font-size: $font-size-nav-top;
    top: 40px;
    right: 0;
    left: unset;
    max-width: 400px;
    min-width: 200px;

    padding: $spacing-sm;
    text-align: left;
    white-space: nowrap;

    ul, li {
      margin: 0;
      padding: 0;
    }

    li {
      list-style-type: none;
      padding: 5px 14px;
    }

    &.infoText {
      min-width: 33vw;
      max-width: 100vw;
      white-space: normal;
      padding: $spacing-lg;
    }
  }

  &:hover {
    .submenu {
      display: block;
      a {
        text-decoration: none;
      }
    }
  }
}