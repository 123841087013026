/* Screen-breaks (from bootstrap) */
$break-xs: 575px;   // portrait phones
$break-sm: 768px;   // tablets
$break-md: 992px;   // small laptops
$break-lg: 1200px;  // big screens

$navbar-width: 245px;
$navigation-padding-bottom: 7px;
$content-width: 1675px;

$link-border-width: 3px;

$iconScrollTopSize: 40px;
