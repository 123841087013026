@import '../settings/fonts';

footer {
  background-color: $manz-gray-dark;
  color: $manz-gray-light;
  font-size: 18px;
  padding-top: 30px;
  padding-bottom: 60px;
  margin-top: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  .footer-content{
    width: 100%;
    display: flex;
    flex-direction: row;

    font-size: 15px;
    color: $manz-gray-medium;

    .social-media-container {
      display: flex;

      .icon-wrapper{
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $manz-gray-medium;

        &+.icon-wrapper{
          margin: 0 0 0 $spacing-sm;
        }
        &:hover {
          background-color: $manz-gray-medium-hover;
        }
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
    .link-container{
      display: flex;
      align-items: center;

      a {
        color: $manz-gray-medium;
        text-decoration: none;
        padding-bottom: .1em;
        border-bottom: 2px solid transparent;
        &:hover{
          border-bottom-color: $manz-gray-medium;
        }
      }
      > *:not(:last-child){
        margin-right: $spacing-md;
      }
    }
  }
}
