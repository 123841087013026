// Importing Variables from Main Project
@import '../../../../Main/styles/settings/colors';
@import '../../../../Main/styles/settings/variables';
@import '../../../../Main/styles/settings/fonts';
@import '../../../../Main/styles/tools/mixins';


.main-nav {
  display: flex;
  align-items: flex-end;
  ul {
    list-style: none;
    margin-bottom: 0;
  }
  li {
    display: inline-block;
    @include font-size-rem($lg-font-size);
    padding: 10px 20px $navigation-padding-bottom 20px;

    border-bottom: 4px solid transparent;
    a {
      color: $text-color;
      text-decoration: none;

      &:hover {
        color: $red;
      }
    }

    &.active {
      border-bottom: 4px solid $red;
      a {
        color: $red;
      }
    }
  }
}
