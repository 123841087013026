@mixin border-radius($args) {
  -webkit-border-radius: $args;
  -moz-border-radius: $args;
  border-radius: $args;
}

@mixin box-shadow($args) {
  -webkit-box-shadow: $args;
  -moz-box-shadow: $args;
  box-shadow: $args;
}

@mixin coloredSVG($color){
  svg {
    .cls-1{
      stroke: $color;
    }
    .cls-2{
      fill: $color;
    }
  }
}

/**
  erzeugt roten hover-effekt, rote linie drunter wenn aktiv
 */
@mixin manz-hover-active-effect(){
  border-bottom: $link-border-width solid transparent;
  &:hover, &.active {
    color: $manz-red;
    text-decoration: none;
    @include coloredSVG($red);
  }
  &.active {
    border-bottom-color: $manz-red;
  }
}

@mixin font-size-rem($px) {
  font-size: $px; // px fallback for IE < 9
  font-size: $px / (16px / 1rem);
}

@mixin phone {
  @media (max-width: $break-sm) { @content; }
}

@mixin tablet {
  @media (max-width: $break-md) { @content; }
}

@mixin activeFocusShadow(){
  @include box-shadow(0 0 2px 0 $call-to-action-blue!important);
}

@mixin isRequired(){
  background-color: $required-bg-color;
}

@mixin error(){
  border: 1px solid $manz-red!important;
}
