@import '../settings/colors';


$search-height: 34px;
$breadcrumb-height: 35px;
$header-height: 75px;

.headerWrapper{
  .header {
    background-color: $manz-white;
    position: relative;
    transition: all 0.2s;
    @include box-shadow(0 6px 5px -2px rgba(0,0,0,0.1));
    height: $header-height;
    display: flex;
    align-items: center;
    padding: 0 1rem;

    .logo {
      width: 140px;
      min-width: 140px;
      height: inherit;
      display: flex;
      position: relative;
      transition: all 0.2s;
      text-align: left;
    }
    .search {
      height: $search-height;
    }
    .rolleStyle{
      width: 100%;
      margin-bottom: 0;
      margin-top: 0;
      height: 100%;

      @media screen and (max-width: map-get($grid-breakpoints, "lg"))  {
        & {
          justify-content: flex-start;
        }
      }
    }
  }

  .burgerMenuHeader {
    outline: none;
    height: 74px;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
  }
  #burger-menu-logo{
    width: 140px;
  }

  .info {
    border-left: 2px solid $manz-gray-medium;
    font-size: 15px;
    min-width: 240px;
    display: flex;
    justify-content: space-between;

    .infoSupport {
      margin-left: 35px;
    }

    .infoWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .infoText,
      .infoTele {
        color: $manz-red;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }

  .icon-menu {

    display: flex;
    align-items: flex-end;
    margin-left: auto;

    .nav-icons {
      margin: 0;
      padding: 0;
      display: flex;
    }

    .nav-icon {
      width: 40px;
      height: 40px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      list-style-type: none;
      @include manz-hover-active-effect();

      &:hover {
        border-bottom-color: $manz-red;
      }

      .icon {
        width: 30px;
        height: 30px;
      }

      & > span {
        display: flex;
        align-items: center;
      }

      .alert-number {
        position: absolute;
        background-color: $manz-red;
        color: white;
        border-radius: 50%;
        font-size: $xxs-font-size;
        width: 15px;
        height: 15px;
        top: 5px;
        right: 5px;
        text-align: center;
      }
    }
  }
  /* overlay shadow kann hier adjustiert werden*/
  .bm-overlay {
    background: rgba(0, 0, 0, 0) !important;
    top: 0;
    bottom: 0;
  }

  .bm-menu-wrap{
    top: 5px;
  }
}


.beteiligteListHeader {
  margin-top: $spacing-xl;
  h6 {
    margin-bottom: $spacing-xs;
  }
}

.margin-bridge{
  top: 36px!important;
}


.headerLandingPage{
  .rolleStyle {
    justify-content: space-between!important;
  }
  .burger-logo{
    display: flex;
    align-items: center;
    button {
      background-color: transparent;
      border: none;
    }
  }
  .bm-menu-wrap {
    @include box-shadow(-1px 10px 10px 0px rgba(0, 0, 0, 0.13));
    .bm-item-list{
      li {
        width:100%;
      }
    }
  }
}

.schriftsatz-header-deprecated {
  position: relative;
  top: 4px;
  left: 4px;
}
