.datepicker {
  position: relative;

  .react-datepicker-wrapper, .react-datepicker__input-container {
    width: 100%;
  }
  .react-datepicker-popper{
    z-index: 3;

    > div > div {
      display: flex;
    }
  }
  .icon {
    position: absolute;
    font-size: 1em;
    top: 1px;
    right: 5px;
    height: 28px;
    border: none;
    background: transparent;
    width: 28px;
    padding: 0;

    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
}

.datepicker-jahr-btn {
  border: 1px solid $manz-gray-medium-hover;
}
