.portal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1050;
  background-color: black;
  opacity: .8;
}

.portal-content {
  z-index: 1051;
}
