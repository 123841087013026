
.padding-content{
  padding: 0 $spacing-md;
}

.container-gray{
  border: 1.5px solid $medium-grey;
  background-color: $manz-gray-light;
  padding-top: $spacing-md;
  padding-bottom: $spacing-md;
}

.help-icon {
  position: relative;
  float: right;

  .erledigung & {
    top: -45px;
  }
}

.gerichte-container{
  .container {
    position: relative;
    right: -21px
  }

  .input {
    width: 302px;
    height: 30px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 14px;
    border: 1px solid #aaa;
  }

  .inputFocused {
    outline: none;
  }

  .inputOpen {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .suggestionsContainer {
    display: none;
  }

  .suggestionsContainerOpen {
    display: block;
    position: absolute;
    top: 30px;
    width: 500px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 1px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }

  .suggestionsList {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }

  .suggestionHighlighted {
    background-color: #ddd;
  }

  .footer {
    margin: 9px 20px;
    font-size: 12px;
    color: #777;
  }

  .gerichtInputField {
    display: flex;
  }

  .gerichtSelectContainerStyle {
    flex-grow: 1;
  }

  .searchGerichteBtn {
    position: relative;
    border: 1px solid #aaa !important;
  }

}

.gz-container-wrapper {
  .gz-container {
    display: flex;
    justify-content: space-between;
    &>*{
      height:100%;
    }
    .abtnr {
      width: 60px;
      margin-right: $spacing-sm;
    }

    .gattung {
      width: 110px;
      margin-right: $spacing-sm;
    }

    .lfnr {
      width: 80px;
    }

    .jahr {
      width: 65px;
    }

    .pfkdz {
      width: 35px;
    }
  }
}

.adr-container-wrapper {
  .adr-container {
    display: flex;
    justify-content: space-between;

    & > * {
      height: 100%;
    }

    .adresse {
      width: 55%;
      margin-right: $spacing-xs
    }

    .haus-nr {
      width: 15%;
      margin-right: $spacing-xs
    }

    .stiege {
      width: 15%;
      margin-right: $spacing-xs;
    }

    .tuer {
      width: 15%;
    }

    .extra {
      > input {
        background-color: white;
      }
    }
  }
}

.spacer {
  margin: 6px $spacing-sm;
}


.begehrenList {
  min-height: 330px;
}

.minHeight {
  min-height: 330px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.erledigung{
  .downloadContainer{
    .documentDownloadMobile {
      width: 100%;
      margin-top: $spacing-sm;
      border: $manz-gray-medium dashed 2px;
      background-color: $bg-color;
      font-size: $font-size;
      transition: border-color 0.2s;

      &:hover {
        border-color: $manz-gray-medium-hover;
        transition: border-color 0.2s;
      }

      .label  {
        @extend .truncate;
        margin: 0 10px;
      }
    }
  }
}

.poll-btn-container {
  width: 100%;
  .last-check {
    text-align: center;
  }
  .navbar & {
    @extend .pt-2;
    @extend .pb-2;
  }

  .btn-blue {
    margin: auto;
    width: 60%;
    display: block;
  }
  .loadingSpinnerBig {
    position: absolute;
  }
}
