$manz-gray: #696969;
$manz-gray-hover: hsl(0, 0%, 49%); // #808080
$manz-gray-dark: #565656;

$manz-gray-medium: #C0C0C0;
$manz-gray-medium-hover: hsl(0, 0%, 87%); // #DEDEDE

$medium-grey: #e2e2e2;
$manz-gray-light: hsl(0, 0%, 94.5%); // #F1F1F1
$manz-gray-light-hover: hsl(0, 0%, 97%); // #F7F7F7

$manz-red: #ae130f;
$manz-red-light: #ff9e98;
$manz-bg: $manz-gray-light;
$manz-white: white;
$manz-black: #000000;
$manz-yellow: #FFEC0A;
$manz-green: #22D686;
$manz-green-light: #a6efce;
$manz-error-red: #DC3545;
$manz-orange: #f4a000;

$manz-blue-light-sm: hsl(228, 89%, 93%);;
$manz-blue-light: hsl(219, 69%, 90%); // #D4E0F7;
$manz-blue-medium: hsl(218, 86%, 86%); // #BCD3FA;
$manz-blue: hsl(219, 70.2%, 55.3%); // #3D75D3;
$manz-blue-dark: hsl(219, 70.2%, 45.3%);

$manz-schriftsatz-in-arbeit: #008BF5;
$manz-schriftsatz-visualisiert: #3d82b7;
$manz-schriftsatz-geprueft: $manz-green-light;
$manz-schriftsatz-eingebracht: $manz-green;
$manz-schriftsatz-gesendet: $manz-yellow;
$manz-schriftsatz-uebertragunsfehler: #3929D8;
$manz-schriftsatz-ungelesen: $manz-gray;
$manz-schriftsatz-fehler: $manz-error-red;
$manz-schriftsatz-gelesen: $manz-gray-light;

$highlight-color: #f5f8fd;
$call-to-action-blue: $manz-blue;
$required-bg-color: #D7E4F8;


$manz-tabs-grey: #F5F5F5;
$manz-tabs-blue: $manz-blue-medium;

$manz-collapse-border-out: #F5F5F5;
$manz-collapse-shadow-out: #a1c3fa;
$manz-collapse-shadow-out-hover: $manz-blue;
$manz-collapge-header-bg: $highlight-color;
//inner collapse
$manz-collapse-background-in: $highlight-color;
$manz-collapse-border-in: $manz-blue-medium;
$manz-collapse-shadow-in: $manz-blue;

$manz-text-disabled: #999999;
$manz-bg-disabled: $manz-gray-light;
$opacity-disabled: 0.5;

$manz-shadow: rgba(0, 0, 0, 0.15);
$table-border-color: $manz-gray-light;

$text-color: $manz-gray-dark;
$manz-form-border-color: $manz-gray-medium;

$red: $manz-red;
$white: #fff;
$blue: $manz-blue;
$bg-color: $manz-bg;
$table-hover-color: $highlight-color;
$outline-color: $call-to-action-blue;
$manz-blink-red: #f1a9a7;
$uebertragunsfehler-send-btn: #cadaf8;
