@import '../settings/fonts';

.input-text {
  min-height: $input-field-height;
  padding-top: 4px;
  border-radius: 1px;
}

.manz-font-size {
  font-size: $font-size;
}

.manz-font-m-size {
  font-size: $font-size-nav-top;
}

.manz-font-size-12 {
  font-size: $font-size-12;
}
