@import '../settings/colors';
@import '../settings/spacings';

.panelHeader {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  padding-right: 15px;

  .left {
    display: flex;
    flex-grow: 1;

    & div:first-child {
      margin-right: $spacing-sm;
    }
  }

  // .right {
  //   display: flex;
  //   justify-content: end;
  // }

  .headerText {
    width: 90%;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
    font-size: 15px;
    letter-spacing: 0.4px;
  }

  .headerTextDeleted {
    color: $manz-gray-medium;
  }

  .headerTextReset {
    position: relative;
    right: -15px;
  }
}

.extraText {
  color: $manz-red;
}

.nr, .heading-spacing {
  margin-right: $spacing-sm;
  width: 23px;
}

.list-header-veraltet-delete {
  background-color: $manz-blink-red;
  border-radius: 3px;

  &-blink {
    @extend .list-header-veraltet-delete;
    animation: changeshadow 2s ease-in infinite;
    -webkit-animation: changeshadow 2s linear infinite;
    -moz-animation: changeshadow 2s linear infinite;
    -ms-animation: changeshadow 2s linear infinite;
    -o-animation: changeshadow 2s linear infinite;
  }
}

