@import '../../Main/styles/settings/fonts';

.bm-menu-wrap {
  @include box-shadow(3px -1px 8px -5px rgba(0,0,0,0.13));
  .bm-morph-shape {
    fill: $manz-white;
  }

  .bm-menu {
    background: $manz-white;
    // font-size: 1.15em;
  }
}
