@import '../settings/colors';

.collapse-btn {
  border: 0 ;
  font-weight: 500;
  display: flex;
  align-items: center;
  &:hover {
    background-color: $manz-gray-medium-hover;
  }
}

.collapse-add-btn {
  position: relative;
  margin-right: 5px;
}

.gb-auszug-add-btn {
  position: relative;
  margin-right: 5px;
  top: -2px;
}

.gb-auszug-begehren-collapse-add-btn {
  position: relative;
  margin-right: 5px;
  top: -2px;
  left: -5px;
}

.collapse-info {
  width: 95%;
  margin-top: -20px;
}

.collapse-header-righttext {
  font-size: 12px;
  font-weight: bold;
  position: relative;
  right: -15px;
}

.collapse-reset-btn {
  position: relative;
  right: 15px;
  top: 6px;
}

.collapse-add-btns {
  display: flex;
  flex-wrap: wrap;
}

.collapse-btn-icon-disabled {
  opacity: 0.4;
}
