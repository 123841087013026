// Importing Variables from Main Project
@import '../../Main/styles/settings/colors';
@import '../../Main/styles/settings/variables';
@import '../../Main/styles/settings/fonts';
@import '../../Main/styles/settings/spacings';
@import '../../Main/styles/tools/mixins';

.formContainer {
  max-width: 960px;
}

.contactForm {
  margin: 20px 0;
}

.btnSubmit {
  border: none;
  background-color: $manz-gray;
  color: white;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  border-radius: 0!important;

  &:hover:not(:disabled) {
    background-color: $manz-gray-hover;
    color: white;
  }
  &:disabled {
    background-color: $manz-gray-medium;
  }
}

.buttonasa {
  font-family: "Roboto", "Arial", sans-serif;
  font-size: inherit;
  text-decoration: none;
  color: $red;
  border: 0;
  background: none;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
}

.captcha {
  @include phone {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    max-width:90%;
    padding: 0;
  }
}

.requiredFields {
  @include font-size-rem($xs-font-size);
  margin-top: 10px;
  text-transform: uppercase;
  text-align: right;
  color: $manz-gray;
}

.form-control-wrapper {
  margin-bottom: 10px;

  .textArea{
    height: 180px;
  }

  .error {
    border: 1px solid $manz-red!important;
  }
  .errorMessage {
    color: $manz-red;
    @include font-size-rem($xs-font-size);
    margin-top: $spacing-xs;
  }
}
