@import '../settings/colors';

.nav-tabs {
  border-bottom: none;
  margin: 7px 0;

  .nav-item:not(:first-child) {
    margin-left: 4px;
  }
  .nav-link {
    font-size: $font-size-nav-top;
    border: none;
    border-bottom: 3px solid transparent; // $manz-white;
    cursor: pointer;
    z-index: 1;
    position: relative;

    &.disabled {
      opacity: .4;

      &:hover {
        border-bottom: none;
        cursor: default;
      }
    }

    &:hover {
      color: $manz-red;
      border-color: $manz-red;
    }
  }
}
