@import '../settings/fonts';

body {
  margin: 0;
  padding: 0;
  font-family: $font-family !important;
  font-size: $font-size;
  background-color: $manz-bg;

  display: flex;
}
