@import '../settings/spacings';

.list-item {
  margin-bottom: $spacing-xs;
  min-height: 33px;

  > div {
    display: inline-block;
    width: 50%;
    height: 100%;

  }
  .label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }

  &+.beispiel {
    display: flex;
    align-items: center;
    padding-left: 0;
  }
}

