@import '../settings/spacings';

.padding-lg {
  padding: $spacing-lg;
}

.padding-bottom-xs {
  padding-bottom: $spacing-xs;
}

.margin-top-sx {
  margin-top: $spacing-xs;
}

.margin-top-sm {
  margin-top: $spacing-sm;
}

.margin-top-md {
  margin-top: $spacing-md;
}

.padding-bottom-md {
  padding-bottom: $spacing-md;
}

.margin-top-xl {
  margin-top: $spacing-xl;
}
.margin-m-top-md {
  @include tablet(){
    margin-top: $spacing-md;
  }
}

.padding-right-40{
  padding-right: 40px;
}

.p-l-0 {
  padding-left: 0;
}

.p-r-0 {
  padding-right: 0;
}

.max-width-800{
  max-width: 800px;
}

.pr-sm {
  padding-right: $spacing-sm;
}

.height-30px,
.height-xl {
  height: $spacing-xl;
}

.input-height {
  height: 33px;
}
