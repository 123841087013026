.abteilung-wrapper {
  font-size: 17px;

  .abteilung-select {
    max-width:500px;
    .react-select {
      &__control {
        border: 0;
        border-bottom: 2px solid $text-color!important;
      }

      &__single-value {
        color: $manz-blue;
      }

      &-icon-custom {
        background-image: url("../../../assets/icons/mz-arrow-menu-dicker.svg");
      }
    }
  }
}

