@import '../settings/colors';

.tabs-grey {
  margin-top: 0 !important;
  margin-left: 5px;
  margin-right: 5px;
  border-style: solid;
  border-top-width: 0;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  min-height: 360px;
  border-color: $manz-tabs-grey;
}

.tabs-blue {
  margin-top: 0 !important;
  margin-left: 5px;
  margin-right: 5px;
  border-style: solid;
  border-top-width: 0;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-color: $manz-tabs-blue;
}

.tabs-content {
  margin: 8px 20px;
  border-width: 0;

  .help-icon {
    margin-top: 0;
  }
  .begehren-content {
     .help-icon {
       margin-top: -40px;
       top: 0;
     }
   }
}

.tabs-flag {
  margin-left: 3px;
  font-size: 12px;
  margin-top: 3px;
}

.tabs-flag:hover {
  font-weight: bold;
}
